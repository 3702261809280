//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from '@amcharts/amcharts4/themes/dark';
import { mapGetters, mapActions } from "vuex";
import eventBus from "@/plugins/eventBus";
import moment from "moment";
import * as _ from "underscore";
am4core.useTheme(am4themes_animated);
export default {
  name: "sessions",
  data() {
    return {
      showChart: true,
      showInnerLoading: false,
      isCall: false,
      busyDay: "",
      busyHour: "",
      sessions_data: [],
      heatmap_data: [],
      dayHoursStats: 0,
      nightHoursStats: 0,
      mostCommonTopic: [],
      chatsData: []
    };
  },
  computed: {
    ...mapGetters({
      getFilterDate: "getFilterDate",
      currentAIAccount: "getCurrentAIAccount",
      currentUser: "getCurrentUser",
      darkTheme: "getDarkTheme"
    }),
    totalSessions() {
      if (this.sessions_data.length > 1) {
        return _.find(this.sessions_data, obj => obj.rowTitle == "TOTSESSIONS").Total;
      }
    },
    totalMessages() {
      if (this.sessions_data.length > 1) {
        return _.find(this.sessions_data, obj => obj.rowTitle == "TOTMESSAGES").Total;
      }
    },
    noOfDays() {
      const start = moment(this.getFilterDate.start, "DD-MM-YYYY");
      const end = moment(this.getFilterDate.end, "DD-MM-YYYY");
      return end.diff(start, "days") + 1;
    },
    averageDailySesion() {
      if (this.sessions_data.length > 0) {
        return (this.totalSessions / this.noOfDays).toFixed(2);
      }
      return 0;
    },
    averageWeeklySesion() {
      if (this.sessions_data.length > 0 && this.noOfDays > 7) {
        let numberofweeks = this.noOfDays / 7;
        return (this.totalSessions / numberofweeks).toFixed(2);
      }
      return this.totalSessions;
    },
    averageMonthlySession() {
      if (this.sessions_data.length > 0 && this.noOfDays > 30) {
        let numberofMonths = this.noOfDays / 30;
        return (this.totalSessions / numberofMonths).toFixed(2);
      }
      return this.totalSessions;
    },
    totalConversationTime(){
      if (this.sessions_data.length > 0){
        return this.toHHMMSS(_.find(this.sessions_data, obj => obj.rowTitle == "TOTDURATION").Total , 'string');
      }else{
      return 0
      }
    },
    averageConversationTime(){
      if (this.sessions_data.length > 0){
        return this.toHHMMSS(Number((_.find(this.sessions_data, obj => obj.rowTitle == "TOTDURATION").Total / this.totalSessions).toFixed(0)), 'string');
      }else{
      return 0
      }
    }
  },
  methods: {
    ...mapActions({
      requestConversationsTopicParameters: "requestConversationsTopicParameters",
      requestSessionsOverview: "requestSessionsOverview"
    }),
    onDateUpdate(date) {
      const that = this;
      if (that.isValid(date.start) && that.isValid(date.end)) {
        that.isCall = false;
        const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: date.start,
          enddate: date.end,
          from: "onDateUpdate"
        };
        that.updateAISesions(data);
      } else {
        console.log("invalid date");
      }
    },
    createBarChartSessions() {
      const that = this;
      if (this.sessionChart) {
        this.sessionChart.dispose();
      }
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark)
      }
      // Create a container
      const container = am4core.create(this.$refs.sessionChart, am4core.Container);
      container.width = am4core.percent(100);
      container.height = am4core.percent(100);
      container.layout = 'vertical';

      // Create chart instance
      const chart = container.createChild(am4charts.XYChart);

      chart.scrollbarX = new am4core.Scrollbar();
      chart.doubleClickDuration = 1200;
      // Add data
      chart.data = this.chatsData.reverse();
      chart.scrollbarX = false;

      // Create axes
      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'start_time';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.renderer.labels.template.horizontalCenter = 'right';
      categoryAxis.renderer.labels.template.verticalCenter = 'middle';
      categoryAxis.renderer.labels.template.rotation = 270;
      categoryAxis.tooltip.disabled = true;
      categoryAxis.renderer.minHeight = 0;

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      // Create series
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.sequencedInterpolation = true;
      series.colorField = 'color';
      series.dataFields.valueY = 'sessions';
      series.dataFields.categoryX = 'start_time';
      series.tooltipText = '[{categoryX}: bold]{valueY}[/]';
      series.columns.template.strokeWidth = 1;
      // series.columns.template.fill = am4core.color("#ff4444");

      series.tooltip.pointerOrientation = 'vertical';

      series.columns.template.column.cornerRadiusTopLeft = 2;
      series.columns.template.column.cornerRadiusTopRight = 2;
      series.columns.template.column.fillOpacity = 0.8;

      // chart series bar width
      if (this.chatsData.length < 10 && this.chatsData.length > 8) {
        series.columns.template.width = am4core.percent(50);
      } else if (this.chatsData.length < 8 && this.chatsData.length > 6) {
        series.columns.template.width = am4core.percent(40);
      } else if (this.chatsData.length < 6 && this.chatsData.length > 4) {
        series.columns.template.width = am4core.percent(30);
      } else if (this.chatsData.length < 4 && this.chatsData.length > 2) {
        series.columns.template.width = am4core.percent(20);
      } else if (this.chatsData.length < 2) {
        series.columns.template.width = am4core.percent(10);
      }

      // double click event
      series.columns.template.events.on(
        'hit',
        (ev) => {
          const ts = new Date().getTime();
          const { index } = ev.target._dataItem;
          if (
            ts - chart.lastClickTs < chart.doubleClickDuration
            && chart.lastClickIndex == index
          ) {
            // it's double click!
            // let's clear the timeout so the "click" event does not fire
            if (chart.clickTimeout) {
              clearTimeout(chart.clickTimeout);
            }

            // reset last click
            chart.lastClickTs = 0;
            chart.lastClickIndex = null;

            // check if duration tab is showing(only enabled for time per hour)
            if (that.showDuration == false) {
              eventBus.updateDateFilter({
                start_date: ev.target._dataItem.dataContext.start_time,
                end_date: ev.target._dataItem.dataContext.start_time,
              });
            }
          } else {
          }
          chart.lastClickTs = ts;
          chart.lastClickIndex = index;
        },
        this,
      );

      // on hover, make corner radiuses bigger
      const hoverState = series.columns.template.column.states.create('hover');
      hoverState.properties.cornerRadiusTopLeft = 0;
      hoverState.properties.cornerRadiusTopRight = 0;
      hoverState.properties.fillOpacity = 1;

      series.columns.template.adapter.add('fill', (fill, target) => chart.colors.getIndex(target.dataItem.index));
      categoryAxis.sortBySeries = categoryAxis.dataFields.category;
      categoryAxis.renderer.inversed = true;
      // disable zoom
      chart.zoomOutButton.disabled = true;

      // disable Scrollbar
      chart.hideXScrollbar = true;
      chart.hideYScrollbar = true;
      // Cursor
      chart.cursor = new am4charts.XYCursor();
      // disable zooming
      chart.cursor.behavior = 'none';
      // disable cursor line
      chart.cursor.lineY.disabled = true;
      chart.cursor.lineX.disabled = true;
      // Disable general cursor tootltip for range view
      valueAxis.cursorTooltipEnabled = false;

      // Enable export
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = `EVO_Dynamics_report-${that.currentAIAccount.name}-Users Sessions-${that.getFilterDate.start}-to-${that.getFilterDate.end}`;
      that.sessionChart = chart;
    },
    updateAISesions(data) {
      const that = this;
      if (!that.isCall) {
        that.showChart = true;
        that.showInnerLoading = true;
        that.isCall = true;
        if (this.sessionChart) {
          this.sessionChart.dispose();
        }
        that.updateMostCommonTopic(data);
        that.requestSessionsOverview(data).then(resp => {
            if (resp.final_data.length > 0) {
              that.sessions_data = resp.final_data;
              that.generateBarChartData(resp.final_data, resp.time_data);
              that.generateHeatMapData(resp.time_data);
              that.generateByDayNighttats(resp.hour_data);
            }
          })
          .catch(err => {
            that.showChart = false;
          })
          .finally(() => {
            setTimeout(() => {
              that.isCall = false;
              that.showInnerLoading = false;
            }, 1000);
          });
      }
    },
    updateMostCommonTopic(data) {
      const that = this;
      that.requestConversationsTopicParameters(data).then(res => {
        let sorted = _.sortBy(res.topicData, "value").reverse();
        that.mostCommonTopic = sorted.slice(0, 3);
      });
    },
    generateHeatMapData(time_data) {
      const that = this;
      const heatmap_data_sorted = [];
      const days_flag = [
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
        "SUNDAY"
      ];
      _.forEach(time_data, record => {
        if (_.contains(days_flag, record.rowTitle)) {
          const weekday = record.rowTitle;
          delete record.rowTitle;
          delete record.total;
          for (const hour in record) {
            if (hour !== "Total") {
              heatmap_data_sorted.push({
                weekday,
                hour,
                value: record[hour]
              });
            }
          }
        }
      });
      that.heatmap_data = heatmap_data_sorted;
      that.generateBusyStats();
    },
    generateByDayNighttats(hour_data) {
      const that = this;
      const hour_dataObj = hour_data[0];
      const nightHoursStats = [];
      const dayHoursStats = [];
      for (const hourNumber in hour_dataObj) {
        if (hourNumber !== "rowTitle") {
          if (hourNumber >= 9 && hourNumber < 18) {
            dayHoursStats.push(hour_dataObj[hourNumber]);
          } else {
            nightHoursStats.push(hour_dataObj[hourNumber]);
          }
        }
      }
      that.dayHoursStats = _.reduce(dayHoursStats, (memo, num) => memo + num, 0);
      that.nightHoursStats = _.reduce(nightHoursStats, (memo, num) => memo + num, 0);
    },
    generateBusyStats() {
      const that = this;
      const { heatmap_data } = that;
      const groupByDay = _.groupBy(heatmap_data, obj => obj.weekday);
      const groupByHour = _.groupBy(heatmap_data, obj => obj.hour);
      const byDayStats = [];
      for (const day in groupByDay) {
        const weekday = day;
        const day_data = _.pluck(groupByDay[day], "value");
        const sum = _.reduce(day_data, (memo, num) => memo + num, 0);
        byDayStats.push({
          weekday,
          count: sum
        });
      }
      const busyDay = _.max(byDayStats, day => day.count);
      that.busyDay = busyDay;
      const byHourStats = [];
      for (const hour in groupByHour) {
        if (hour !== "total") {
          const hour_data = _.pluck(groupByHour[hour], "value");
          const sum = _.reduce(hour_data, (memo, num) => memo + num, 0);
          byHourStats.push({
            hour,
            count: sum
          });
        }
      }
      const busyHour = _.max(byHourStats, day => day.count).hour;
      that.busyHour = busyHour;
    },
    generateBarChartData(data, time_data) {
      const that = this;
      if (that.getFilterDate.start == that.getFilterDate.end) {
        const chartData = [];
        _.forEach(time_data, (row) => {
          if (row.rowTitle == 'TOTSESSIONS') {
            for (const hour in row) {
              if (hour !== 'rowTitle' && hour !== 'Total') {
                chartData.push({
                  start_time: hour,
                  sessions: row[hour],
                });
              }
            }
          }
        });
        that.chatsData = chartData
        that.createBarChartSessions();
      } else {
        const reject_flags = [
          'TOTSESSIONS',
          'TOTMESSAGES',
          'TOTDURATION',
          "MAXMESSAGES",
          'MONDAY',
          'TUESDAY',
          'WEDNESDAY',
          'THURSDAY',
          'FRIDAY',
          'SATURDAY',
          'SUNDAY',
        ];
        const chartData = [];
        _.forEach(data, (row) => {
          if (!_.contains(reject_flags, row.rowTitle)) {
            chartData.push({
              start_time: moment.unix(row.rowTitle / 1000).format('DD MMM YYYY'),
              sessions: row.Total,
            });
          }
        });
        that.chatsData = chartData
        that.createBarChartSessions();
      }
      that.showInnerLoading = false;
    },
    onAccountUpdate(account) {
      const that = this;
      const data = {
        aiAccount: account.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
        from: "onAccountUpdate"
      };
      that.isCall = false;
      that.updateAISesions(data);
    },
    isValid(value) {
      if (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      ) {
        return false;
      }
      return true;
    }
  },
  watch: {
    showChart(newVal) {
      const that = this;
      if (newVal == false) {
        if (that.chart) {
          that.chart.dispose();
        }
      }
    },
    darkTheme(){
      this.createBarChartSessions()
    }
  },
  mounted() {
    const that = this;
    const data = {
      aiAccount: that.currentAIAccount.id,
      startdate: that.getFilterDate.start,
      enddate: that.getFilterDate.end,
      from: "mounted"
    };
    that.updateAISesions(data);
  },
  created() {
    eventBus.$on("updateDate", this.onDateUpdate);
    eventBus.$on("updateAIAccount", this.onAccountUpdate);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    eventBus.$off("updateDate", this.onDateUpdate);
    eventBus.$off("updateAIAccount", this.onAccountUpdate);
  },
  destroyed() {
    eventBus.$off("updateDate", this.onDateUpdate);
    eventBus.$off("updateAIAccount", this.onAccountUpdate);
  }
};
